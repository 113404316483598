import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { User } from 'swingby-shared';
import { userVar, isLoggedInVar } from '../../apollo/cache';
import { MUTATION_APPLE_LOGIN } from '../../apollo/mutations';
import { DocumentType, IResult, IUserWithToken } from '../../apollo/types';
import { signupAppleIdState, signupEmailState } from '../../atoms';
import client from '../../lib/axiosClient';

type appleLoginResponse = {
  email: string;
  refresh_token: string;
  appleId: string;
};

const useAppleLogin = () => {
  const router = useRouter();
  const setAppleId = useSetRecoilState(signupAppleIdState);
  const setEmail = useSetRecoilState(signupEmailState);

  const [mutate, { error }] = useMutation<IResult<IUserWithToken>>(MUTATION_APPLE_LOGIN, {
    onCompleted({ result }) {
      if (!result.token) return router.push('/signup/terms-acceptance');
      userVar(result.user as DocumentType<User>);
      isLoggedInVar(true);
      globalThis?.localStorage?.setItem('token', result.token);
      router.push('/');
    },
    onError(err) {
      console.error(err.message);
    },
  });

  const appleLogin = (appleId: string, email: string) => {
    setAppleId(appleId);
    setEmail(email);
    mutate({ variables: { appleId } });
  };

  const getAppleAuth = async (code: string) => {
    const { data } = await client.post<appleLoginResponse>('/api/appleSignin', { code });
    return data;
  };

  return { appleLogin, error, getAppleAuth };
};

export default useAppleLogin;
